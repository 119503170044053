// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v723demo3',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiUrl: 'http://iotapi-test.netgroup.mobi/api/v1',
  grafanaUrl: 'http://iotdashboard-test.netgroup.mobi',
  flowUrl:'http://iotflow-test.netgroup.mobi',
  bimUrl:'',
  redirectUriWso: 'http://iotmanagement-test.netgroup.mobi/index.html',
  authUrlWso:'https://iotids-test.netgroup.mobi',
  revocationEndpoint:'https://iotids-test.netgroup.mobi/oauth2/revoke',
  clientIdWso:'sjYKH3CymfV11VKLDCc9Q2zpz_Ea'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
