import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, throwError } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ConfigService } from 'src/app/config-service/config.service';
import { UserModel } from '../_models/user.model';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
    providedIn: 'root',
  })
export class WSOService  {
  public authUrl= this.configService.authUrlWso;

  public authConfig : AuthConfig  = {

    issuer: `${this.configService.authUrlWso}/oauth2/oidcdiscovery`,

    clientId: this.configService.clientIdWso,
    
    requireHttps:false,

    redirectUri: this.configService.redirectUriWso,
    postLogoutRedirectUri: this.configService.redirectUriWso,
    skipIssuerCheck: true,
    strictDiscoveryDocumentValidation: false,
    responseType: 'code',
    revocationEndpoint: this.configService.revocationEndpoint,
    userinfoEndpoint: `${this.configService.authUrlWso}/oauth2/userinfo`,
    disablePKCE: true,
    scope: 'openid profile email offline_access backend_api ',
    silentRefreshTimeout: 50000,
    timeoutFactor: 0.75, 
    sessionChecksEnabled: true,
    showDebugInformation: true,
    clearHashAfterLogin: false,
 };

  
   // Set this to true to enable the auto-login feature
  public readonly autoLogin = true;
  public isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserModel : UserModel;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest(
    this.isAuthenticated$,
    this.isDoneLoading$
  ).pipe(map(values => values.every(b => b)));

   public getUser(){
     if(this.currentUserModel){
      return this.currentUserModel;
     }else{
      let user = new UserModel();
      user.accessToken = this.accessToken;
      let identityClaims:any = this.identityClaims;
      user.firstname = identityClaims.given_name;
      user.lastname = identityClaims.family_name;
      return user;
     }
   }

  public navigateToLoginPage() {
    // TODO: Remember current URL
    this.router.navigateByUrl('/auth/login');
  }  

  constructor (
    public oauthService: OAuthService,
    private http: HttpClient,
    private configService:ConfigService,
    private cookieService:CookieService,
    private router: Router
  ) {
    
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUserModel = undefined;
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();

    // Useful for debugging:
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error(event);
      } else {
        console.warn(event);
        if(event.type == "token_expires"){
          //this.logout();
          this.oauthService.refreshToken().then((token)=>{
                     console.log(token)
          })
         // this.refresh();
        }
      }
    });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup.
    // window.addEventListener('storage', (event) => {
    //   console.log(event)
    //   // The `key` is `null` if the event was caused by `.clear()`
    //   if (event.key !== 'access_token' && event.key !== null) {
    //     console.log(event.key)
    //     if(event.key =="token_expires"){
    //       this.logout()
    //     }
    //     console.log("here")
    //     return;
    //   }

    //   console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
    //   this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

    //   if (!this.oauthService.hasValidAccessToken()) {
    //     this.navigateToLoginPage();
    //   }
    // });

    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events
      .subscribe(_ => {
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      });

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(e => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(e => this.navigateToLoginPage());

    this.oauthService.setupAutomaticSilentRefresh();
  }


  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }


  // public runInitialLoginSequence(): Promise<void> {
  //   if (location.hash) {
  //     console.log('Encountered hash fragment, plotting as table...');
  //     console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
  //   }

  //   // 0. LOAD CONFIG:
  //   // First we have to check to see how the IdServer is
  //   // currently configured.
  //   //
  //   // IMPORTANT: To make the OIDC discovery work on WSO2 IS
  //   // you have to unsecure the oidc dicovery endpoint (a spa must never know admin credentials).
  //   // - Open the file <IS_HOME>/repository/conf/identity/identity.xml
  //   // - Find this line
  //   //      <Resource context="(.*)/.well-known(.*)" secured="true" http-method="all"/>
  //   // - Set secure attribute to false
  //   return this.oauthService.loadDiscoveryDocument()

  //     // For demo purposes, we pretend the previous call was very slow
  //     .then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 1000)))

  //     // 1. HASH LOGIN:
  //     // Try to log in after redirect back
  //     // from IdServer from initLoginFlow:
  //     .then(() => this.oauthService.tryLogin())

  //     .then(() => {
  //       if (this.oauthService.hasValidAccessToken()) {
  //         return Promise.resolve();
  //       }

  //       // 2. SILENT LOGIN:
  //       // Try to log in via silent refresh because the IdServer
  //       // might have a cookie to remember the user, so we can
  //       // prevent doing a redirect:
  //       return this.refresh()
  //         .then(() => Promise.resolve())
  //         .catch(result => {
  //           if (this.checkUserInteractionRequiredOnRefreshFailure(result)) {

  //             // 3. ASK FOR LOGIN:
  //             // At this point we know for sure that we have to ask the
  //             // user to log in, so we redirect them to the IdServer to
  //             // enter credentials.
  //           //  if (this.authConfig.autoLogin) {
  //               // Force user to login
  //               //console.log('Forcing user to log in');
  //               this.login();
  //             //}
  //            // else {
  //            //   console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
  //            // }
  //             return Promise.resolve();
  //           }

  //           // We can't handle the truth, just pass on the problem to the
  //           // next handler.
  //           return Promise.reject(result);
  //         });
  //     })

  //     .then(() => {
  //       this.isDoneLoadingSubject$.next(true);

  //       // Check for the strings 'undefined' and 'null' just to be sure. Our current
  //       // login(...) should never have this, but in case someone ever calls
  //       // initImplicitFlow(undefined | null) this could happen.
  //       if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
  //         console.log('There was state, so we are sending you to: ' + this.oauthService.state);
  //         this.router.navigateByUrl(this.oauthService.state);
  //       }
  //     })
  //     .catch(() => this.isDoneLoadingSubject$.next(true));
  // }

  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      console.log('Encountered hash fragment, plotting as table...');
      console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
    }

    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured.
    //
    // IMPORTANT: To make the OIDC discovery work on WSO2 IS
    // you have to unsecure the oidc dicovery endpoint (a spa must never know admin credentials).
    // - Open the file <IS_HOME>/repository/conf/identity/identity.xml
    // - Find this line
    //      <Resource context="(.*)/.well-known(.*)" secured="true" http-method="all"/>
    // - Set secure attribute to false
    return this.oauthService.loadDiscoveryDocument()

      // For demo purposes, we pretend the previous call was very slow
      .then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 1000)))

      // 1. HASH LOGIN:
      // Try to log in after redirect back
      // from IdServer from initLoginFlow:
      .then(() => this.oauthService.tryLogin())

      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve();
        }

        // 2. SILENT LOGIN:
        // Try to log in via silent refresh because the IdServer
        // might have a cookie to remember the user, so we can
        // prevent doing a redirect:
        return this.refresh()
          .then(() => Promise.resolve())
          .catch(result => {
            if (this.checkUserInteractionRequiredOnRefreshFailure(result)) {

              // 3. ASK FOR LOGIN:
              // At this point we know for sure that we have to ask the
              // user to log in, so we redirect them to the IdServer to
              // enter credentials.
              if (this.autoLogin) {
                // Force user to login
                console.log('Forcing user to log in');
                this.login();
              }
              else {
                console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
              }
              return Promise.resolve();
            }

            // We can't handle the truth, just pass on the problem to the
            // next handler.
            return Promise.reject(result);
          });
      })

      .then(() => {
        this.isDoneLoadingSubject$.next(true);

        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          console.log('There was state, so we are sending you to: ' + this.oauthService.state);
          this.router.navigateByUrl(this.oauthService.state);
        }
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  private checkUserInteractionRequiredOnRefreshFailure(result: any): boolean {
    // Only consider situations where it's reasonably sure that sending the
    // user to the IdServer will help.
    const errorCodes = [
      // OAuth2 error codes
      // See RFC https://tools.ietf.org/html/rfc6749#section-5.2
      'invalid_grant',

      // OIDC error codes
      // See https://openid.net/specs/openid-connect-core-1_0.html#AuthError
      'interaction_required',        
      'login_required',
      'account_selection_required',
      'consent_required'
    ];

    // Notice that implicit and code flows return errors in different ways
    const k = this.oauthService.responseType === 'code' ? 'error' : 'reason';

    return result
        && result[k]
        && errorCodes.indexOf(result[k].error) >= 0;
  }


  public initWsoService(){
    this.authConfig = this.configService.authConfig;
    console.log(this.authConfig)
    this.oauthService.configure(this.authConfig);
    this.oauthService.setStorage(localStorage);
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(()=>{
      if(this.hasValidToken()){
        let user = new UserModel();
        user.accessToken = this.accessToken;
        let identityClaims:any = this.identityClaims;
        //console.log(identityClaims)
        user.firstname = identityClaims.given_name;
        user.lastname = identityClaims.family_name;
        this.currentUserModel = user;
        this.currentUserSubject = new BehaviorSubject<UserModel>(user)
        this.router.navigate(['/dashboard']);
      }
    })
    
  }

  public login(targetUrl?: string) {
    this.oauthService.initLoginFlow(encodeURIComponent(targetUrl || this.router.url));
  }
  public logout() {
    
      // const token = this.oauthService.getAccessToken(); 
      // console.log(token)
      // this.revokeToken(token);
      
      // this.oauthService.logOut();
      // localStorage.clear();
      // sessionStorage.clear()
      // this.router.navigate(["/auth/login"]);
          this.oauthService.configure(this.configService.authConfig);
          this.oauthService.loadDiscoveryDocument().then(()=>{
            this.oauthService.revokeTokenAndLogout().then(()=>{
              localStorage.clear();
              sessionStorage.clear();
             this.router.navigate(["/auth/login"]);
            })
          })
     
  }


  logoutExternally() {
    window.open(this.logoutUrl);
  }
  public refresh(): Promise<object> {
    return this.oauthService.responseType === 'code'
      ? this.oauthService.refreshToken()
      : this.oauthService.silentRefresh();
  }

  public hasValidToken() { return this.oauthService.hasValidAccessToken(); }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() { return this.oauthService.getAccessToken(); }
  public get refreshToken() { return this.oauthService.getRefreshToken(); }
  public get identityClaims() { return this.oauthService.getIdentityClaims(); }
  public get idToken() { return this.oauthService.getIdToken(); }
  public get logoutUrl() { return this.oauthService.logoutUrl; }

  // Revoke access token
  // Notice that WSO2 IS 5.8.0 automatically revokes the associated refresh token
  // (check response headers of access token revocation) which looks very reasonable.
  private revokeToken(token: string) {
    console.log('Revoking token = ' + token);
    const revocationUrl = this.authConfig.revocationEndpoint;//this.oauthService.tokenEndpoint.replace(/\/token$/, '/revoke');
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('token', token);
    urlSearchParams.append('token_type_hint', 'access_token');
    urlSearchParams.append('client_id', this.authConfig.clientId);
    this.http.post(revocationUrl, urlSearchParams.toString(), { headers })
      .subscribe(result => {
          console.log('Access token and related refresh token (if any) have been successfully revoked');
      }, (error) => {
          console.error('Something went wrong on token revocation');
          //this.oidcSecurityService.handleError(error);
          return throwError(error);
      });
  }
}