import { Route } from '@angular/compiler/src/core';
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { tap, windowWhen } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { WSOService } from './wso.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,private wsoService: WSOService,private actRouter:Router,private router:Router ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(this.wsoService.hasValidToken())
    {
      return true;
    }else{
      this.router.navigate(["/auth/login"]);
      return false;
    }
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.authService.logout();
    return false;
  }
}
