// ITA
export const locale = {
  lang: 'it',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
    },
    MENU: {
      NEW: 'new',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
    },
    GENERAL:{
      ADD: 'Aggiungi'
    },
    DATACONVERTER : { TITLE : 'DataConverter'},
    DATAMODELCONFIG : { TITLE : 'Config'},
    FLOWS : { TITLE : 'Flows'},
    TOOLS : { TITLE : 'Estensioni'},
    LABELS : {
      EXPLAIN_CONFIGURAZIONE: 'Selezionare quale configurazione visualizzare nella tabella con la possibilitá di aggiungerne delle nuove. ',
      EXPLAIN_DATACONVERTER: 'Associare un data converter ti permette di transformare i dati che vengono ricevuti dal device',
      EXPLAIN_STRATEGY: 'Utilizzare una Strategy ti permette di poter tener sempre sotto controllo il ciclo di vita del device, é possibile scegliere vari metadata in base alla strategy scelta',
      EXPLAIN_COMMANDS: 'Creare un command ti da la possibiltá di inviare determinati dati al device configurato',
      EXPLAIN_SECURITY: 'Generare una chiave di decriptazione per rendere la comunicazione dei dispositivi end-to-end e rendere sicura la trasmissione dei dati.',
      EXECUTE : 'Esegui',
      EXECUTE_LABEL : 'ESEGUI : ',
      COMMANDS : 'Comandi', 
      ACTIVATED : 'Attivo',
      DEACTIVE: 'Inattivo',
      UPDATE : 'Aggiorna',
      DELETE : 'Elimina',
      CLONE : 'Clona',
      AUTH_TYPE:'Endpoint di Comunicazione',
      CLOSE : 'Chiudi',
      USER : 'Informazioni Utente',
      NAME : 'Nome : ',
      SURNAME : 'Cognome : ',
      EMAIL : 'Email : ',
      PHONE : 'Telefono : ',
      POSITION : 'Posizione : ',
      OTHER_ACTION: "Altre Azioni",
      EXPORT_XLSX: "Esporta come XLSX",
      EXPORT_CSV: "Esporta come CSV",
      EXPORT_JSON:"Esporta come JSON",
      EXPORT_PARQUET: "Esporta come PARQUET",
      FAST_SAVE: 'Aggiornamento rapido'
    },
    TABLE : {
      FILTER: 'Filtro',
      TABLE_HEADER_NAME : 'Nome',
      TABLE_HEADER_ID : 'UUID',
      TABLE_HEADER_SURNAME : 'Cognome',
      TABLE_HEADER_EMAIL : 'Email',
      TABLE_HEADER_DEVICEID: 'Device id',
      TABLE_HEADER_DEVICEMODEL:'Template',
      TABLE_HEADER_ACTIONS : 'Azioni',
      TABLE_HEADER_DATEINSTALLED : 'Data Installazione',
      TABLE_HEADER_STATE : 'Stato'
    },
    DEVICE : {
      TITLE : 'Device',
      CREATE: 'Crea un nuovo device',
      FIRST_STEP : '1) Informazioni Principali',
      SECOND_STEP : '2) Informazioni Aggiuntive',
      THIRD_STEP : '3) Posizione',
      FUNCTION_STEP : '4) Functions & Security',
      DEVICE_SUMMARY : '5) Riassunto',
      NAME_LABEL : 'Inserire il nome',
      NAME_SPAN: 'Perfavore inserire il nome del device.',
      MODAL:{
        HEADER_AUTH : 'Autenticazione',
        LABEL : 'Tipo Autenticazione',
        HEADER_ENDPOINT : 'Endpoint di Comunicazione',
        HEADER_QRCODE : "Identificativo Device",
        MQTT: 'MQTT',
        MQTT_MANIPULATE: 'MQTT TRANSFORM',
        HTTP : 'HTTP',
        AMQP : 'AMQP',
        COAP : 'COAP'
      }
    },
    LORAWAN : {
      TITLE : 'Lorawan'
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    }
  }
};
