import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WSOService } from './wso.service';

@Injectable()
export class MyInterceptor implements HttpInterceptor {
    constructor(private wsoServ:WSOService){}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     let accesstoken = this.wsoServ.accessToken;
     if(accesstoken == null)   return next.handle(httpRequest);
     const reqAuth = httpRequest.clone({
         setHeaders : {
             Authorization : 'Bearer '+accesstoken
         }
     })
    // httpRequest.headers.append('Authorization','Bearer '+accesstoken);
    return next.handle(reqAuth);
  }
}
