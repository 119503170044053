import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { AuthConfig } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {


    public apiUrl;
    public grafanaUrl;
    public flowUrl;
    public bimUrl;
    public redirectUriWso;
    public authUrlWso;
    public revocationEndpoint;
    public clientIdWso;
    public br_proxy;
    public flinkUrl;
    public springUrl;
    public customLogo;
    public mqtt_frontend;
    public nodeUrl;
    public amqp;
    public coap;
    public authConfig:AuthConfig;
    constructor(private http: HttpClient) {
     // this.loadConfig();
    }

    loadConfig() {
        return this.http
          .get<any>('./assets/config.json')
          .toPromise()
          .then(config => {
            this.apiUrl = config.apiUrl;
            this.grafanaUrl= config.grafanaUrl;
            this.bimUrl = config.bimUrl;
            this.flowUrl= config.flowUrl;
            this.nodeUrl=config.nodeUrl
            this.springUrl= config.springUrl;
            this.flinkUrl= config.flinkUrl;
            this.redirectUriWso= config.redirectUriWso;
            this.authUrlWso= config.authUrlWso;
            this.revocationEndpoint= config.revocationEndpoint;
            this.clientIdWso= config.clientIdWso;
            this.br_proxy = config.br_proxy;
            this.mqtt_frontend = config.mqtt_frontend;
            this.amqp = config.amqp;
            this.coap = config.coap;
            this.customLogo = config.customLogo;
            this.authConfig = {

              issuer: `${this.authUrlWso}/oauth2/oidcdiscovery`,
          
              clientId: this.clientIdWso,
              
              requireHttps:false,

              redirectUri: this.redirectUriWso,
              postLogoutRedirectUri: this.redirectUriWso,
              userinfoEndpoint: `${this.authUrlWso}/oauth2/userinfo`,
              skipIssuerCheck: true,
              strictDiscoveryDocumentValidation: false,
              responseType: 'code',
              revocationEndpoint: this.revocationEndpoint,
              disablePKCE: true,
              scope: 'openid profile email offline_access backend_api ',
              silentRefreshTimeout: 50000,
              timeoutFactor: 0.75, 
              sessionChecksEnabled: true,
              showDebugInformation: true,
              clearHashAfterLogin: false,
           };
            
          });
      }

}