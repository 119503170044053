import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LogoutComponent } from './logout/logout.component';
import { AuthComponent } from './auth.component';
import {TranslationModule} from '../i18n/translation.module';
import { AuthConfig, JwksValidationHandler, OAuthModule, OAuthStorage, ValidationHandler } from 'angular-oauth2-oidc';
import { WSOService } from './_services/wso.service';
import { AuthGuard } from './_services/auth.guard';
import { CookieService } from 'ngx-cookie-service';
export function storageFactory() : OAuthStorage {
  return localStorage
}

@NgModule({
  declarations: [
    LoginComponent,
    RegistrationComponent,
    ForgotPasswordComponent,
    LogoutComponent,
    AuthComponent,
  ],
  imports: [
    CommonModule,
    TranslationModule,
    AuthRoutingModule,
    FormsModule,
    OAuthModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers:[
    CookieService,
    WSOService,
    AuthGuard
  ]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: AuthModule,
      providers: [
       // { provide: ValidationHandler, useClass: JwksValidationHandler },
        { provide: OAuthStorage, useFactory: storageFactory },
      ]
    };
  }

}
